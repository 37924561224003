<script lang="ts">
  import type { z } from 'zod';
  import { footballTablesSchema } from '@amedia/beethoven-schemas';
  import { css } from '@amedia/brick-tokens';
  import { slide } from 'svelte/transition';

  import Dropdown from '$components/style/dropdown/dropdown.svelte';
  import Card from '$components/style/card/card.svelte';
  import Button from '$components/style/button/button.svelte';

  type DivisionId = 1 | 2 | 3 | 4 | 5 | 6 | undefined;

  type DivisionIdsTable = {
    [team: string]: DivisionId;
  };

  let divisionIdsTable: DivisionIdsTable = {};

  const rowStyle = css({
    display: 'grid',
    gridTemplateColumns: '$x6 1fr repeat(6, $x8)',
    padding: '0 $x3',
    borderBottom: '1px solid #12121210',
    alignItems: 'center',
    fontstyle: 'baseMetaM',

    '& > p': {
      textAlign: 'center',
      marginBlock: '$x3',
    },

    '& > span': {
      overflow: window.innerWidth <= 768 ? 'hidden' : 'unset',
    },

    variants: {
      header: {
        true: {
          gridTemplateColumns: '1fr repeat(6, $x8)',
          '& > span': {
            padding: '$x1',
          },
        },
        false: {
          gridTemplateColumns: '$x6 1fr repeat(6, $x8)',
          '& > span': {
            '& > p': {
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              hyphens: 'auto',
            },
          },
        },
      },
    },
  });

  const teamStyle = css({
    display: 'flex',
    alignItems: 'center',
  });

  const dividingLinesStyle = css({
    display: 'flex',
    alignItems: 'center',
    fontstyle: 'baseMetaS',

    '&::before': {
      content: '',
      display: 'block',
      width: '2px',
      height: '$x3',
      marginRight: '$x2',
      borderRadius: '$baseS',
    },

    variants: {
      inBottomPanel: {
        false: {
          marginLeft: 'calc(-1 * $x2)',
        },
        true: {
          paddingInline: '$x2',
          paddingBlock: '0px $x1',
          margin: '0px',
        },
      },
      type: {
        undefined: {
          backgroundColor: 'unset',
        },
        // promotion / opptrykk
        1: {
          '&::before': {
            backgroundColor: '#009217',
          },
        },
        // relegation / nedtrykk ?
        2: {
          '&::before': {
            backgroundColor: '#920200',
          },
        },
        // Play off for promotion / opptrykkskvalikk
        3: {
          '&::before': {
            backgroundColor: '#004C92',
          },
        },
        // Play off for relegation / nedtrykksvalikk
        4: {
          '&::before': {
            backgroundColor: '#FFB029',
          },
        },
        // International cup /
        5: {
          '&::before': {
            backgroundColor: '#009217',
          },
        },
        // international cup 2
        6: {
          '&::before': {
            backgroundColor: '#004C92',
          },
        },
      },
    },
  });

  const logoStyle = css({
    width: '16px',
    height: '16px',
    marginRight: '$x2',
  });

  const pointsStyle = css({
    background: '#12121210',
    padding: '4px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    width: '$x4',
    justifySelf: 'center',
  });

  const bottomStyle = css({
    padding: '$x4',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0 0 $baseL $baseL ',

    variants: {
      showBottomPanel: {
        true: {
          display: 'flex',
          paddingBlock: '$x2',
          fontstyle: 'baseMetaS',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          paddingInline: '$x2',
        },
        false: {
          paddingTop: '$x2',
        },
      },
    },
  });

  let items: {
    title: string;
    clickValue: string;
    onClick?: () => void;
    text?: string;
  }[] = [];

  const getDivisionLineName = (mark: number) => {
    switch (mark) {
      case 1:
        return 'Opprykk';
      case 2:
        return 'Nedrykk';
      case 3:
        return 'Opprykkskvalikk';
      case 4:
        return 'Nedrykkskvalikk';
      case 5:
        return 'Champions League';
      case 6:
        return 'Conference League';

      default:
        break;
    }
  };

  function getDivisionLine(divisionId: number): DivisionId {
    if (divisionId > 0 && divisionId < 7) return divisionId as DivisionId;
    else return undefined;
  }

  async function getTables(): Promise<z.infer<typeof footballTablesSchema>> {
    const filteredResponse: z.infer<typeof footballTablesSchema> = [];

    const response: z.infer<typeof footballTablesSchema> = await fetch(
      '/api/beethoven/v1/sport/football/tables'
    ).then((res) => res.json());

    for (const entry of response) {
      if (!entry || 'error' in entry) continue;
      try {
        const dividingLines = entry.table.tableDividingLines;

        items.push({
          title: entry.tournament.name,
          clickValue: entry.tournament.name,
          onClick: () => (activeTableIndex = response.indexOf(entry)),
        });
        for (const team of entry.table.teams) {
          if (dividingLines) {
            const dividingLine = dividingLines.find(
              (divisionLine) =>
                divisionLine.fromPlace <= team.place &&
                divisionLine.toPlace >= team.place
            );
            if (dividingLine) {
              divisionIdsTable[team.name + entry.tournament.id] =
                dividingLine.typeId as DivisionId;
            }
          }
        }

        filteredResponse.push(entry);
      } catch {
        // we got some error. While fetching data from nifs
        // not likely it will happen again, but it is useful to inform the user in some way
      }
    }

    return filteredResponse;
  }

  $: cutoffIndex = 6;

  $: activeTableIndex = 0;
</script>

{#await getTables() then tables}
  {@const activeTable = tables[activeTableIndex].table.teams}
  {@const tournament = tables[activeTableIndex].tournament}
  <Card padding="$x1 0 0 0 " borderRadius="$baseL">
    <span class={rowStyle({ header: true })}>
      <Dropdown
        {items}
        title={tournament?.name}
        leftAnchored
        activeIndexes={[activeTableIndex]}
        contentTitle="Bytt til:"
        transparent
        clickLabel="footballTable"
        clickValue={tournament?.name}
      />
      <p>K</p>
      <p>V</p>
      <p>U</p>
      <p>T</p>
      <p>+/-</p>
      <p>P</p>
    </span>
    <!-- Instead of using overflow, I just slice the table. This way I don't have to worry about overflow cutting off the dropdown -->
    {#each tables[activeTableIndex].table.teams.slice(0, cutoffIndex) as team, index}
      <span class={rowStyle({ header: false })} transition:slide>
        <p
          class={dividingLinesStyle({
            type: divisionIdsTable[team.name + tournament.id],
            inBottomPanel: false,
          })}
        >
          {index + 1}
        </p>
        <span class={teamStyle()}>
          <img class={logoStyle()} src={team.logo} alt={team.name + ' logo'} />
          <p>{team.name}</p>
        </span>
        <p>
          {team.played}
        </p>
        <p>
          {team.won}
        </p>
        <p>
          {team.draw}
        </p>
        <p>
          {team.lost}
        </p>
        <p>
          {team.goalDifference}
        </p>
        <p class={pointsStyle()}>
          {team.points}
        </p>
      </span>
    {/each}
    {#if cutoffIndex >= activeTable.length}
      {@const tableDividingLines =
        tables[activeTableIndex].table.tableDividingLines}
      <div class={bottomStyle({ showBottomPanel: true })}>
        {#if tableDividingLines}
          {#each tableDividingLines as divisionLine}
            <p
              class={dividingLinesStyle({
                type: getDivisionLine(divisionLine.typeId),
                inBottomPanel: true,
              })}
            >
              {getDivisionLineName(divisionLine.typeId)}
            </p>
          {/each}
        {/if}
      </div>
    {:else}
      <div class={bottomStyle({ showBottomPanel: false })}>
        <Button
          text="Se hele tabellen"
          clickLabel="footballTable"
          clickValue="expandTable"
          type="tertiary"
          icon="arrow-expand"
          iconPosition="right"
          on:click={() => {
            cutoffIndex = activeTable.length;
          }}
          fullWidth
        />
      </div>
    {/if}
  </Card>
{/await}
