<script lang="ts">
  import '../untold-carousel/brick-carousel.wrapper';

  import { css } from '@amedia/brick-tokens';

  import CarouselTeaser from '../carousel-teaser/carousel-teaser.svelte';

  import Card from '$components/style/card/card.svelte';
  import { publicationsList } from '$stores/sites-store';
  import { getHumanReadableDate } from '$utils/time';
  import { ReflexWidth, buildReflexUrl } from '$utils/sanity-image';

  type Papers = {
    sitekey: string;
    id: string;
    issuedate: string;
    publication: { name: string; type: string };
    title: string;
    url: string;
  }[];

  type Assets = {
    teasers: {
      id: string;
      cover: {
        images: { medium: string; small: string };
      };
    }[];
  };

  type PapersAndAssets = {
    issuedate: string;
    title: string;
    url: string;
    thumb: string;
  };

  let papersList: PapersAndAssets[] = [];

  export let selectedSites: string[] | undefined;

  const contentStyle = css({
    display: 'flex',
    maxWidth: '250px',
    flexDirection: 'column',
    borderRadius: '$baseL',
    border: '1px solid $baseBg',
    outline: '',
    textDecoration: 'none',
    color: '$baseFg',
  });

  const imageStyle = css({
    borderRadius: '$baseL',
  });

  const textStyle = css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  });

  const headerStyle = css({
    fontstyle: 'baseHeadlineM',
    margin: '$x2',
  });

  const metaStyle = css({
    fontstyle: 'baseMetaM',
    marginTop: '0px',
  });

  const carouselStyleOverride = css({
    '[data-contents-wrapper]': {
      gridTemplateColumns: `repeat(${
        papersList?.length | 0
      }, minmax(250px, 1fr)) !important`,
      scrollPaddingInlineStart: '$x4',
      paddingInlineStart: '$x4 !important',
      paddingInlineEnd: '$x4 !important',
      '--brick--contentGap': '0px !important',
    },
  });

  const now = new Date().getTime();

  $: keysToFilter = selectedSites || [];

  async function getPublicationNeighbors() {
    const publications = await publicationsList;
    const papersResponse = await fetch(
      'https://services.api.no/api/camelot/v2/publications/papers'
    ).then((res) => res.json());

    const papers: Papers = papersResponse.papers;
    const assets: Assets = papersResponse.assets;

    publications.forEach((publication) => {
      if (selectedSites?.includes(publication.siteKey)) {
        publication.neighbours?.forEach((neighbor) => {
          if (!keysToFilter?.includes(neighbor.siteKey)) {
            keysToFilter?.push(neighbor.siteKey);
          }
        });
      }
    });

    const keysLength = keysToFilter.length;

    if (keysLength < 3) {
      while (keysToFilter.length < 8) {
        const randomKey =
          papers[Math.floor(Math.random() * papers.length)].sitekey;
        if (!keysToFilter.includes(randomKey)) {
          keysToFilter.push(randomKey);
        }
      }
    }

    papersList = papers
      .map(({ issuedate, sitekey, title, url }, i) => {
        if (keysToFilter?.includes(sitekey)) {
          return {
            issuedate: issuedate,
            title: title,
            url: url,
            thumb: assets.teasers[i].cover.images.medium,
          };
        }
      })
      .filter((item): item is PapersAndAssets => item !== undefined)
      .sort(() => Math.random() - 0.5);
  }

  getPublicationNeighbors();
</script>

<CarouselTeaser
  class={carouselStyleOverride()}
  logoSrc={buildReflexUrl(
    'https://cdn.sanity.io/images/ogtltof7/production/23fd487dae3bdc147bd84179b98bb3f66f332e43-48x48.png',
    ReflexWidth.W680
  )}
  title="eAvis-kiosken"
  subtitle="Les papirutgaven på nett"
>
  {#each papersList as { url, issuedate, title, thumb }}
    {@const released = new Date(issuedate).getTime()}
    <Card padding="0px">
      <a
        href={url}
        class={contentStyle()}
        data-adp-clickLabel="epaper"
        data-adp-clickValue={title}
      >
        <img
          class={imageStyle()}
          src={thumb}
          alt={'Bilde av dagens utgave av ' + title}
        />
        <div class={textStyle()}>
          <h2 class={headerStyle()}>{title}</h2>
          <p class={metaStyle()}>
            {now - released < 86400000
              ? 'Dagens utgave'
              : getHumanReadableDate(issuedate, true)}
          </p>
        </div>
      </a>
    </Card>
  {/each}
</CarouselTeaser>
