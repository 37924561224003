import { BrickAltTeaser } from '@amedia/brick-alt-teaser';

import Component from './alt-page.component.svelte';

import { SvelteElement, defineCustomElements } from 'templating-tool';

const componentName = 'amedia-alt-page';

document.querySelector('amedia-alt-page').innerHTML = '';

export default class AltPage extends SvelteElement {
  constructor(props) {
    super({
      Component,
      options: { ...props, componentName },
    });
  }
}

defineCustomElements({
  'brick-alt-teaser-v4': BrickAltTeaser,
  [componentName]: AltPage,
});
