<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import Card from '$components/style/card/card.svelte';
  import Button from '$components/style/button/button.svelte';

  export let title: string;
  export let leftLogo: string;
  export let rightLogo: string;
  export let href: string;
  export let startTime: string;
  export let dateString: string;
  export let endTime: string;
  export let currentTime: number;

  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const isDirect =
    currentTime <= endDate.getTime() && currentTime >= startDate.getTime();

  const linkStyle = css({
    color: 'black',
    textDecoration: 'none',
  });

  const cardStyle = css({
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #f4efe9',
    borderRadius: '$baseXl',
    overflow: 'hidden',
    boxSizing: 'border-box',
    height: '100%',
  });

  const coverStyle = css({
    display: 'flex',
    width: '100%',
    minHeight: '100px',
    backgroundColor: '#3D195B',
    borderRadius: '$baseL',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  });

  const imageStyle = css({
    borderRadius: '99px',
    width: '64px',
    height: '64px',
    border: '1px solid #f4efe9',
    zIndex: 1,
    backgroundSize: '75%',
    backgroundPosition: 'center',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    marginInline: '$x1',
  });

  const textBoxStyle = css({
    padding: '$x4',
    userSelect: 'none',
    isolation: 'isolate',
    height: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  });

  const headerStyle = css({
    fontstyle: 'baseHeadlineL',
    textWrap: 'balance',
    margin: '0px',
    marginBottom: '$x4',
  });

  const buttonStyle = css({
    '& > .mozart-button': {
      '& > span': {
        padding: '$x2',
      },
    },
  });
</script>

<a
  class={linkStyle()}
  {href}
  data-adp-clickLabel="untold"
  data-adp-clickValue={title}
>
  <Card padding="0px" height="250px">
    <div class={cardStyle()}>
      <div class={coverStyle()}>
        <div
          class={imageStyle()}
          style={`background-image: url(${leftLogo})`}
        />
        <div
          class={imageStyle()}
          style={`background-image: url(${rightLogo})`}
        />
      </div>
      <div class={textBoxStyle()}>
        <div>
          <h3 class={headerStyle()}>
            {title}
          </h3>
          {#if isDirect}
            <div class={buttonStyle()}>
              <Button
                size="none"
                {href}
                title="Direkte"
                clickLabel="untold"
                type="signal">Direkte</Button
              >
            </div>
          {:else}
            <div class={buttonStyle()}>
              <Button
                size="none"
                {href}
                title="Direkte"
                clickLabel="untold"
                type="tertiary">{dateString}</Button
              >
            </div>
          {/if}
        </div>
      </div>
    </div>
  </Card>
</a>
