<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import CarouselTeaser from '../carousel-teaser/carousel-teaser.svelte';

  import FootballMatchCard from './football-match-card.svelte';

  import Card from '$components/style/card/card.svelte';
  import { getHumanReadableDate } from '$utils/time';
  import { user } from '$stores/user-store';
  import { SPORTS_DIRECT_LOGO } from '$utils/consts';

  type Match = {
    acpId: string;
    startTime: string;
    awayTeam: Team;
    homeTeam: Team;
    image: string;
    matchStatus: 'played' | 'not_started' | 'cancelled' | 'ongoing' | undefined;
    title: string;
    score?: { home: number; away: number };
    tournament: { name: string; season: string };
    videoUrls: { native: string; web: string };
  };

  type TeamPromise = {
    name: string;
    acpId: string;
    gender: string;
    tagType: string;
  };

  type Team = {
    id: number;
    logo: string;
    name: {
      short: string;
      full: string;
    };
  };

  const linkStyle = css({
    textDecoration: 'none',

    '&:empty': {
      display: 'none',
    },
  });

  const carouselStyleOverride = css({
    '[data-contents-wrapper]': {
      scrollPaddingInlineStart: '$x4',
      paddingInlineStart: '$x4 !important',
      paddingInlineEnd: '$x4 !important',
      '--brick--contentGap': '0px',
    },
  });

  const direkteSportCardStyle = css({
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    textAlign: 'center',
    fontstyle: 'baseHeadlineL',
    margin: 'auto',
    width: '75%',
    justifyContent: 'center',
    color: 'black',
  });

  const iconStyle = css({
    height: '40px',
    width: '40px',
    borderRadius: '99px',
    backgroundImage: `url(${SPORTS_DIRECT_LOGO})`,
    backgroundSize: '65%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#01172B',

    variants: {
      header: {
        true: {
          height: '35px',
          width: '35px',
          gridRow: 'span 2',
        },
        undefined: {
          marginBottom: '$x4',
        },
      },
    },
  });

  async function getMatches(): Promise<Match[]> {
    const teamIds = $user.data?.teams.map((team) => team.id) || [];

    const publicationsQuery = $user.data?.preferredSites
      .map((item) => `siteKeys=${item.siteKey}&`)
      .join('');

    if (publicationsQuery) {
      const teamsPromise: TeamPromise[] = await fetch(
        `api/beethoven/v1/sport/football/teams?${publicationsQuery}`
      ).then((res) => res.json());

      teamsPromise.forEach((team) => teamIds.push(team.acpId));
    }

    const footballMatchesResponse = await fetch(
      `api/beethoven/v1/sport/football/matches?teams=${teamIds.join(
        ','
      )}limit=10`
    ).then((res) => res.json());

    return footballMatchesResponse;
  }
</script>

{#await getMatches() then matches}
  <CarouselTeaser
    class={carouselStyleOverride()}
    background="rgba(0,0,0,0.1)"
    title="Direktesport"
    subtitle="Tettest på norsk idrett."
    itemWidth="300px"
  >
    <svelte:fragment slot="logo">
      <span class={iconStyle({ header: true })} />
    </svelte:fragment>
    {#each matches as { title, tournament, image, videoUrls, startTime, matchStatus, awayTeam, homeTeam, score }}
      <a
        class={linkStyle()}
        href={videoUrls.web}
        data-adp-clickLabel="footballCarousel"
        data-adp-clickValue={title}
      >
        <Card padding="1px" borderRadius="$baseL" height={'unset !important'}>
          <FootballMatchCard
            startTime={new Date(startTime)}
            {title}
            {matchStatus}
            {image}
            homeScore={score?.home}
            awayScore={score?.away}
            awayTeamLogo={awayTeam.logo}
            homeTeamLogo={homeTeam.logo}
            humanReadableDate={getHumanReadableDate(startTime)}
            tournamentName={tournament.name}
          />
        </Card>
      </a>
    {/each}
    <a
      class={linkStyle()}
      href={'https://direktesport.no'}
      data-adp-clickLabel="footballCarousel"
      data-adp-clickValue={'direktesport'}
    >
      <Card
        padding="1px"
        borderRadius="$baseL"
        height={'385px !important'}
        background="$baseNeutral00"
      >
        <span class={direkteSportCardStyle()}>
          <span class={iconStyle()} />
          Se enda flere sportssendinger på Direktesport
        </span>
      </Card>
    </a>
  </CarouselTeaser>
{/await}
