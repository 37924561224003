<script>
  import { css } from '@amedia/brick-tokens';
  import { logout } from '@amedia/user';

  import Icon from '$components/style/icon/icon.svelte';
  import i18n from '$i18n/i18n.js';

  export let accessDomain = '';

  const listStyle = css({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: '$x3',
  });

  const linkStyle = css({
    color: '$baseFg',
    textDecoration: 'none',
    fontstyle: 'baseBodyS',
    fontFeatureSettings: "'ss02'",
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '$x2',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover, &:focus-visible': {
      color: '$baseFgWeak',

      '& > svg': {
        fill: '$baseFgWeak',
      },
    },
  });

  const hrStyle = css({
    borderWidth: '0.5px',
    borderColor: 'rgba(0, 0, 0, 0.15)',
    marginBlock: '$x3',
  });

  const iconSize = 24;

  const links = [
    {
      label: 'about',
      href: `?#info`,
      text: 'settings.user.about',
      icon: 'arrow-right',
      internal: true,
    },
    {
      label: 'abo',
      href: `https://${accessDomain}/abo`,
      text: 'settings.user.mySubscription',
      icon: 'link-external-thin',
    },
    {
      label: 'aid-profil',
      href: 'https://www.aid.no/aid/profil',
      text: 'settings.user.myAid',
      icon: 'link-external-thin',
    },
    {
      label: 'cmp-settings',
      href: 'https://www.alt.no/vis/annonseinnstillinger',
      text: 'settings.user.adSettings',
      icon: 'link-external-thin',
    },
    {
      label: 'privacy',
      href: 'http://amedia.no/personvern',
      text: 'settings.user.privacy',
      icon: 'link-external-thin',
    },
  ];

  function getLinkAttr(internal) {
    if (internal) return {};
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }
</script>

<ul class={listStyle()}>
  {#each links as { label, href, text, icon, internal }}
    {@const linkAttr = getLinkAttr(internal)}
    <li>
      <a
        {href}
        {...linkAttr}
        class={linkStyle()}
        data-adp-clickLabel="myalt"
        data-adp-clickValue={label}
        >{$i18n.t(text)}
        <Icon {icon} size={iconSize} />
      </a>
    </li>
    <hr class={hrStyle()} />
  {/each}
  <li>
    <a
      href="/logg_ut"
      class={linkStyle()}
      data-adp-clickLabel="myalt"
      data-adp-clickValue="logout"
      on:click|preventDefault={logout}
      >{$i18n.t('global.logOut')}
      <Icon icon="logout" size={iconSize} />
    </a>
  </li>
</ul>
