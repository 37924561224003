<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import CountdownPill from './countdown-pill.svelte';

  export let matchStatus:
    | 'played'
    | 'not_started'
    | 'cancelled'
    | 'ongoing'
    | undefined;
  export let startTime: Date;
  export let title: string;
  export let humanReadableDate: string | null;
  export let tournamentName: string;
  export let image: string;
  export let homeTeamLogo: string | undefined;
  export let awayTeamLogo: string | undefined;
  export let homeScore: number | undefined;
  export let awayScore: number | undefined;

  const imageStyle = css({
    height: '383px',
    borderRadius: 'calc($baseL - 1px)',
    objectFit: 'contain',
    display: 'flex',
    flexDirection: 'column-reverse',
    backgroundImage: `linear-gradient(180deg, rgba(18, 18, 18, 0.00) 66%, #121212 100%), url(${`https://g.acdn.no/api/reflex/v1/image/resize/300_400/${encodeURIComponent(
      image
    )}`})`,
  });

  const textBoxStyle = css({
    color: 'white',
    padding: '$x4',
  });

  const headerStyle = css({
    display: 'flex',
    flexDirection: 'rows',
    justifyContent: 'space-between',
    marginBottom: '$x4',
  });

  const teamsContainer = css({
    display: 'flex',
    flexDirection: 'rows',
  });

  const iconStyle = css({
    height: '40px',
    width: '40px',
    borderRadius: '99px',
    backgroundSize: '75%',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    variants: {
      moveRight: {
        true: {
          marginRight: '-10px',
          zIndex: 1000,
        },
      },
      moveLeft: {
        true: {
          marginLeft: '-10px',
        },
      },
    },
  });

  const pillWrapper = css({
    position: 'relative',
    marginBlock: 'auto',
  });

  const dotStyle = css({
    height: '8px',
    width: '8px',
    borderRadius: '55%',
    background: 'black',
    marginRight: '4px',
  });

  const timeStampStyle = css({
    borderRadius: '99px',
    background: '$baseBg',
    color: 'black',
    fontstyle: 'baseMetaM',
    height: '32px',
    margin: 0,
    marginBlock: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '$x4',

    variants: {
      direct: {
        true: {
          background: '$buttonSignalActiveBg',
          color: '$baseFg',
          marginLeft: 'calc(-1 * $x4)',
        },
      },
      recording: {
        true: {
          background: 'black',
          color: 'white',
        },
      },
      pill: {
        true: {
          marginRight: '$x3',
          marginLeft: 'calc(-1 * $x4)',
        },
      },
    },
  });

  const titleStyle = css({
    marginBlock: '0px',
    fontstyle: 'baseHeadlineL',
  });

  const leagueStyle = css({
    marginBlock: '0px',
    fontstyle: 'baseBodyS',
  });
</script>

<div class={imageStyle()}>
  <span class={textBoxStyle()}>
    <span class={headerStyle()}>
      {#if homeTeamLogo && awayTeamLogo && homeScore && awayScore}
        <span class={teamsContainer()}>
          <span
            class={iconStyle({
              moveRight: homeScore > awayScore,
            })}
            style={`background-image: url(${homeTeamLogo});`}
          />
          <span
            class={iconStyle({
              moveLeft: homeScore < awayScore,
            })}
            style={`background-image: url(${awayTeamLogo});`}
          />
        </span>
      {/if}
      {#if matchStatus === 'not_started'}
        <p class={timeStampStyle()}>
          {#if startTime.toDateString() === new Date().toDateString() && matchStatus === 'not_started'}
            <CountdownPill {startTime} />
          {:else}
            {humanReadableDate}
          {/if}
        </p>
      {:else if matchStatus}
        <span class={pillWrapper()}>
          <span class={timeStampStyle()}>
            {#if matchStatus === 'ongoing'}
              <p class={timeStampStyle({ direct: true, pill: true })}>
                <span class={dotStyle()} />
                Direkte
              </p>
            {:else if matchStatus === 'played'}
              <p class={timeStampStyle({ recording: true, pill: true })}>
                Opptak
              </p>
            {/if}
            <p>
              {#if matchStatus === 'cancelled'}
                Avlyst
              {:else}
                {homeScore} - {awayScore}
              {/if}
            </p>
          </span>
        </span>
      {/if}
    </span>
    <h2 class={titleStyle()}>{title}</h2>
    <p class={leagueStyle()}>{tournamentName}</p>
  </span>
</div>
