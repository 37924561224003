<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import './brick-carousel.wrapper';

  import CarouselTeaser from '../carousel-teaser/carousel-teaser.svelte';

  import UntoldCard from './untold-card.svelte';

  import { UNTOLD_LOGO } from '$utils/consts';

  type SportsResult = {
    hits: number;
    results: Episode[];
  };

  type Episode = {
    acp_id: string;
    banner: UntoldImage;
    categories: string[];
    cover: UntoldImage;
    description: string;
    full: UntoldImage;
    hero: UntoldImage;
    name: string;
    producer: string;
    state: string;
    type: string;
    universal_link_url: string;
  };

  type UntoldImage = {
    blurhash: string;
    large: string;
    medium: string;
    small: string;
  };

  const footballUntoldResponse: Promise<SportsResult> = fetch(
    'https://admin.api.no/api/content/search/podcast?q=anyOf(categories=sport,categories=fotball)'
  ).then((res) => res.json());

  const carouselStyleOverride = css({
    '[data-contents-wrapper]': {
      scrollPaddingInlineStart: '$x4',
      paddingInlineStart: '$x4 !important',
      paddingInlineEnd: '$x4 !important',
      '--brick--contentGap': '0px',
    },
  });
</script>

{#await footballUntoldResponse then episodes}
  <CarouselTeaser
    class={carouselStyleOverride()}
    logoSrc={UNTOLD_LOGO}
    title="Untold"
    subtitle={'Sportspodkaster'}
    itemWidth="300px"
  >
    {#each episodes.results as { cover, name, producer, universal_link_url, description }}
      <UntoldCard
        title={name}
        {description}
        author={producer}
        href={universal_link_url}
        cover={cover.medium}
        duration={0}
      />
    {/each}
  </CarouselTeaser>
{/await}
