<script lang="ts">
  import { css } from '@amedia/brick-tokens';

  import ModalDialog from '../modal/modal-dialog.svelte';
  import Icon from '../icon/icon.svelte';

  import DropdownModal from './dropdown-modal.svelte';

  export let items: {
    title: string;
    clickValue: string;
    onClick?: () => void;
    text?: string;
    disabled?: boolean;
  }[];

  export let contentTitle = '';
  export let idKey = '';
  export let title = '';
  export let leftAnchored = false;
  export let transparent = false;
  export let activeIndexes: number[] | undefined = undefined;
  export let clickLabel: string;
  export let clickValue: string;

  const isMobile = window.innerWidth <= 768;

  const containerStyle = css({
    display: 'flex',
    flexDirection: leftAnchored ? 'column' : 'undefined',
  });

  const dialogParentStyle = css({
    position: isMobile ? 'unset' : 'relative',
  });

  const dialogStyle = css({
    variants: {
      isMobile: {
        true: {
          left: '0px',
          top: '0px',
          margin: 'unset',
          position: 'fixed',

          '&::backdrop': {
            backgroundColor: '#12121275',
          },
        },
        false: {
          top: '100%',
          transform: leftAnchored ? 'unset' : 'translateX(-100%)',
        },
      },
    },
  });

  const buttonStyle = css({
    display: 'flex',
    fontstyle: 'baseMetaM',
    border: 'unset',
    alignItems: 'center',
    padding: 'unset',
    cursor: 'pointer',
    color: '$buttonPrimaryFg',
    '-webkit-text-fill-color': '$buttonPrimaryFg',
    background: 'none',

    '& > span': {
      display: 'flex',
      maxWidth: '100%',

      '& > span': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },

      '& > svg': {
        marginLeft: '$x2',
        flex: 'none',
      },
    },

    '&:hover > span': {
      transform: 'scale(0.95)',
    },

    variants: {
      transparent: {
        true: {
          backgroundColor: 'unset',
        },
        false: {
          '& > span': {
            padding: '$x1 $x3',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '$baseL',
          },
        },
      },
    },
  });

  let dialogComponent: HTMLDialogElement;

  const handleModalClose = () => {
    dialogComponent.close();
    open = false;
  };

  const handleDialogOpen = () => {
    open = true;
    if (isMobile) {
      dialogComponent.showModal();
      return;
    }
    dialogComponent.show();
  };

  $: open = false;
</script>

<span class={containerStyle()}>
  <button
    class={buttonStyle({ transparent: transparent })}
    data-adp-clickLabel={clickLabel}
    data-adp-clickValue={clickValue}
    on:click={() =>
      !dialogComponent.open ? handleDialogOpen() : handleModalClose()}
  >
    <span>
      <span>
        {title}
      </span>
      <Icon icon={'arrow-expand'} size={16} />
    </span>
  </button>

  <div class={dialogParentStyle()}>
    <ModalDialog
      on:close={() => (open = false)}
      bind:dialogComponent
      class={dialogStyle({ isMobile: isMobile })}
      margin={isMobile ? 'auto' : 'unset'}
    >
      <DropdownModal
        {open}
        {idKey}
        {isMobile}
        {clickLabel}
        {items}
        {handleModalClose}
        {activeIndexes}
        {contentTitle}
      />
    </ModalDialog>
  </div>
</span>
