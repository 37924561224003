<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import './brick-carousel.wrapper';

  import CarouselTeaser from '../carousel-teaser/carousel-teaser.svelte';

  import PremierLeagueCard from './premier-league-card.svelte';

  import { UNTOLD_LOGO } from '$utils/consts';

  type SportsResult = {
    feed: Feed;
    id: string;
    original: boolean;
    producer: string;
    startTime: string;
    teams: Team[];
    dateString: string;
  };

  type Feed = {
    endTime: string;
    language: string;
    startTime: string;
    streamUrl: string;
  };

  type Team = {
    badgeUrl: string;
    name: string;
  };

  const untoldResponse: Promise<SportsResult[]> = fetch(
    '/api/beethoven/v1/untold/premierLeague'
  ).then((res) => res.json());

  const carouselStyleOverride = css({
    '[data-contents-wrapper]': {
      scrollPaddingInlineStart: '$x4',
      paddingInlineStart: '$x4 !important',
      paddingInlineEnd: '$x4 !important',
      '--brick--contentGap': '0px',
    },
  });
</script>

{#await untoldResponse then episodes}
  <CarouselTeaser
    class={carouselStyleOverride()}
    logoSrc={UNTOLD_LOGO}
    title="Untold"
    subtitle={'Hør Premier League direkte'}
    itemWidth="300px"
  >
    {#each episodes as { feed, teams, dateString }}
      <PremierLeagueCard
        title={teams[0].name + ' - ' + teams[1].name}
        startTime={feed.startTime}
        endTime={feed.endTime}
        href="https://untold.app/premier-league"
        currentTime={new Date().getTime()}
        leftLogo={teams[0].badgeUrl}
        rightLogo={teams[1].badgeUrl}
        {dateString}
      />
    {/each}
  </CarouselTeaser>
{/await}
