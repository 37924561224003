export const persistPWA = () => {
  const params = new URLSearchParams(location.search);
  const isPWA = params.get('src') === 'pwa';
  if (isPWA) {
    localStorage.setItem('pwa', 'true');
    window.history.replaceState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    );
  }
};
