<script lang="ts">
  export let startTime: Date;

  const padWithZero = (num: number) => {
    return num < 10 ? '0' + num : num;
  };

  const getCountdown = (startTime: Date) => {
    now = new Date();

    let seconds = Math.floor((startTime.valueOf() - now.valueOf()) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    minutes = minutes - hours * 60;
    seconds = seconds - hours * 60 * 60 - minutes * 60;

    countdown = `${padWithZero(hours)}:
      ${padWithZero(minutes)}:
      ${padWithZero(seconds)}`;
  };

  $: now = new Date();
  $: countdown = '';

  setInterval(() => getCountdown(startTime), 1000);
</script>

{countdown}
